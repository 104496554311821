<template>
  <div
      class="
      d-flex
      align-items-center"
  >
    <div class="container-fluid">
      <router-view/>
      <insert-balance-modal></insert-balance-modal>
      <Dialpad v-if="isAuthRoute"></Dialpad>
    </div>
  </div>
</template>

<script>
import "../src/assets/css/libs.bundle.css";
import "../src/assets/css/theme.bundle.css";
import "../src/assets/js/theme.js";

import Dialpad from "./components/Dialpad";
import InsertBalanceModal from "./components/layout/partials/InsertBalanceModal";

export default {
  name: "app",
  components: {InsertBalanceModal, Dialpad},

  computed: {
    isAuthRoute() {
      return this.$router.currentRoute.value.meta.auth;
    }
  }
};

</script>

<style scoped>
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>