import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import './assets/css/app.css'
import 'vue-multiselect/dist/vue-multiselect.css'

axios.interceptors.request.use((config) => {
    config.url = process.env.VUE_APP_API_URL + config.url
    if (config.params === undefined) {
        config.params = {}
    }

    config.headers = {'Authorization': `Bearer ${store.getters['auth/getToken']}`};

    config.headers['Access-Control-Allow-Origin'] = '*'
    return config;
});

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.response !== undefined) {
        if (error.response.status === 401) {
            router.push({
                name: 'login'
            });

            return Promise.reject(error)
        }
    }

    return Promise.reject(error)
})
import VueApexCharts from "vue3-apexcharts";
import VTooltip from 'v-tooltip';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const cors = require('cors')

const app = createApp(App)

app.use(VTooltip);
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.component('apexchart', VueApexCharts)
app.use(cors)
app.use(store)
app.use(router)
app.mount('#app')
