<template>
    <div class="row">
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 50)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 50 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 100)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 100 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 150)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 150 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 200)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 200 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 250)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 250 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <div class="card">
                <div class="card-body" @click='$emit("valueClicked", 300)'>
                    <div class="row align-items-center gx-0">
                        <div class="col balance-col">
                            <span class="mb-0"> R$ 300 </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ValuesModal",
        emits: ["valueClicked"]
    }
</script>

<style scoped>
    .card-body {
        background: white;
        cursor: pointer;
    }
    .balance-col {
        text-align: center;
        font-size: 30px;
    }
</style>