import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters"

const state = {
    lastCalls: {},
    qualifications: {},
    agentsMetrics: {},
    callsTotal: {},
    callsTotalAnswered: {},
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}