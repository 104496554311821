import axios from "axios";
import store from '../store/index'
import moment from "moment";

export default class ActionCallsService {
    fetchQualifications(commit, type, filter) {
        if (store.getters['auth/getCampaign'] === undefined) {
            store.dispatch('auth/fetchUserCampaign')
            store.dispatch('dashboard/fetchQualifications')
        }
        let uri = `/campaigns/${store.getters['auth/getCampaign'].id}/qualifications/total`;
        let params = {};

        if (filter !== undefined) {
            // eslint-disable-next-line no-prototype-builtins
            if (filter.hasOwnProperty('dates')) {
                params.start_date = filter.dates.start_date_to_qualification.toString();
                params.end_date = filter.dates.start_date_to_qualification.toString();
            }
        }

        axios.get(uri, {
            params: params,
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(response => {
            commit(type, response.data.data.qualifications);
        })
    }

    fetchCalls(commit, type, page, search, filter) {
        let uri = "/calls";

        let params = {
            page: page,
            number: search ? search : '',
            per_page: 10,
        };

        // eslint-disable-next-line no-empty,no-prototype-builtins
        if (filter.hasOwnProperty('dates')) {
            if (filter.date !== null) {
                params.start_date = filter.dates.start_date.toString();
                params.end_date = filter.dates.end_date.toString();
            }
        }

        // eslint-disable-next-line no-empty,no-prototype-builtins
        if (filter.hasOwnProperty('status')) {
            if (filter.status !== null) {
                params.status = filter.status.join(',');
            }
        }

        // eslint-disable-next-line no-empty,no-prototype-builtins
        if (filter.hasOwnProperty('qualifications')) {
            if (filter.qualifications !== null) {
                params.qualification = filter.qualifications.join(',');
            }
        }

        // eslint-disable-next-line no-empty,no-prototype-builtins
        if (filter.hasOwnProperty('users')) {
            if (filter.users !== null) {
                params.users = filter.users;
            }
        }

        axios.get(uri, {
            params: params,
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(response => {
            commit(type, response);
        })
    }

    fetchTotalCallsValue(commit, type, filter, status) {
        let uri = "/calls/total";

        let params = {
            start_date: moment().subtract(30, 'days').format('yyyy-MM-DD 00:00:01'),
            type: 'all',
        };

        if (status) params.statuses = status;

        if (filter !== undefined) {
            // eslint-disable-next-line no-prototype-builtins
            if (filter.hasOwnProperty('dates')) {
                params.start_date = filter.dates.start_date.toString();
                params.end_date = filter.dates.end_date.toString();
            }
        }

        axios.get(uri, {
            params: params,
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then((response) => {
            commit(type, response.data.data);
        })
    }

    fetchAgentsMetrics(commit, type) {
        if (store.getters['auth/getCampaign'] === undefined) {
            store.dispatch('auth/fetchUserCampaign')
            store.dispatch('dashboard/fetchAgentsMetrics')
        }
        const uri = `/campaigns/${store.getters['auth/getCampaign'].id}/agents/metrics/total`;

        let params = {
            start_date: moment().format('yyyy-MM-DD 00:00:01'),
            end_date: moment().format('yyyy-MM-DD 23:59:59'),
        };

        axios.get(uri, {
            params: params,
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then((response) => {
            commit(type, response.data.data);
        })
    }
}
