import TcSDK from '@3cplus/3cplusv2-sdk'
import store from '@/store'

let csrfToken = ''
let socketAddr = process.env.VUE_APP_TCPLUS_SOCKET_URL

let sdk = true

let isInit = false

const init = () => {
  sdk = new TcSDK({
    baseURL: '',
    headers: {
      'X-CSRF-TOKEN': csrfToken
    }
  })
  .v1()
  .withAuth(store.getters['auth/getToken'])
  if (socketAddr && !isInit) {
    isInit = true
    sdk = sdk.socket(socketAddr)
  }
}

export default () => {
  init()
  return sdk
}
