export default {
    nextStep({commit}, step) {
        commit('setStep', step);
    },

    startModal({commit}, payload) {
        commit('setStartModal', payload);
    },

    resetState({commit}) {
        commit('resetState')
    },

    resetPasswordModal({commit}, payload) {
        commit('setResetPasswordModal', payload);
    },

    closeResetPasswordModal({commit}) {
        commit('closeResetPasswordModal');
    },
}
