export default {
    setQualificationList(state, payload) {
        state.qualificationsList = payload;
    },

    setQualification(state, payload) {
        state.qualifications = payload;
    },

    setQualificationData(state, payload) {
        state.qualifications.data = payload;
    },

    setQualificationToUpdate(state, payload) {
        state.qualificationToUpdate = payload;
    }
}