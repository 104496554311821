<template>
    <div class="modal fade" id="balanceModal" aria-labelledby="balanceModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="balanceModalLabel">Recarga via Pix</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <values-modal @valueClicked="onValueClicked" v-if="!hasValue"></values-modal>
                    <company-data-modal @recharge="onRecharge" v-if="hasValue && !wait_payment"></company-data-modal>
                    <payment-modal :qrcode="qrcode" :qrcodebase64="qrcodebase64" v-if="wait_payment"></payment-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ValuesModal from "./ValuesModal";
    import CompanyDataModal from "./CompanyDataModal";
    import PaymentModal from "./PaymentModal";
    import axios from 'axios'

    export default {
        name: "InsertBalanceModal",
        components: {ValuesModal, CompanyDataModal, PaymentModal},
        data() {
            return {
                hasValue: false,
                value: 0,
                wait_payment: false,
                qrcode: '',
                qrcodebase64: ''
            }
        },
        methods: {
            onValueClicked(value) {
                this.hasValue = true
                this.value = value
            },
            onRecharge () {
                axios.post('/payment/pix', { transaction_amount: this.value, qevo: true })
                    .then((response) => {
                        this.qrcodebase64 = response.data.qr_code_base64
                        this.qrcode = response.data.qr_code
                        this.wait_payment = true
                    })
            }
        },

        computed: {
            user() {
                return this.$store.getters['auth/user']
            }
        }
    }
</script>

<style scoped>
.modal-body {
    background: #f9fbfd;
    height: auto !important;
}

.card-body {
    background: white;
}
.balance-col {
    text-align: center;
    font-size: 30px;
}
</style>