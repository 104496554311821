export default {
    getWelcomeModalStep(state) {
        return state.step;
    },

    getStartModal(state) {
        return state.startModal;
    },

    getResetPasswordModal(state) {
        return state.resetPasswordModal;
    }
}
