<template>
    <div>
        <div>
            <form @submit.prevent="onSubmit">
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="firstName" class="col-form-label">Primeiro Nome</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" id="firstName" class="form-control" v-model="company.first_name">
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="lastName" class="col-form-label">Sobrenome</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" id="lastName" class="form-control" v-model="company.last_name">
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="email" class="col-form-label">E-mail</label>
                    </div>
                    <div class="col-md-9">
                        <input type="email" id="email" class="form-control" v-model="company.email">
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="document" class="col-form-label">CPF/CNPJ</label>
                    </div>
                    <div class="col-md-9">
                        <input
                          type="text" id="document" class="form-control"
                          v-model="company.user_document"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="cep" class="col-form-label">CEP</label>
                    </div>
                    <div class="col-md-9">
                        <input
                          v-on:keyup="searchZipCode"
                          v-model="company.zip_code"
                          type="text" id="cep" class="form-control">
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="address" class="col-form-label">Logradouro</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" id="address" class="form-control"
                           v-model="company.street_name"
                        >
                    </div>
                    <div class="col-md-1">
                        <label for="number" class="col-form-label">N°</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" id="number" class="form-control"
                            v-model="company.street_number"
                        >
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-3">
                        <label for="city" class="col-form-label">Cidade</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" id="city" class="form-control" v-model="company.neighborhood">
                    </div>
                    <div class="col-md-4">
                        <label class="col-form-label">{{ company.city }} - {{ company.federal_unit }}</label>
                    </div>
                </div>
                <div class="row g-3 align-items-center">
                    <div class="col-md-12 align-items-right">
                        <button type="submit" class="btn btn-primary">Avançar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "CompanyDataModal",
        emits: ["recharge"],
        data() {
            return {
                company: {},
                errors: {}
            }
        },
        mounted() {
            axios.get('/company-data')
              .then(response => {
                  if (response.data.data !== undefined) {
                      this.company = response.data.data
                  }
              })
        },
        methods: {
            searchZipCode () {
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(() => {
                    if (this.company.zip_code.length === 8) {
                        fetch('https://viacep.com.br/ws/' + this.company.zip_code + '/json/')
                            .then(response => response.json())
                            .then(data => {

                                if (data.erro === true) {
                                    this.errors = {
                                        zip_code: 'erro'
                                    }
                                } else {
                                    this.company.street_name = data.logradouro
                                    this.company.neighborhood = data.bairro
                                    this.company.city = data.localidade
                                    this.company.federal_unit = data.uf
                                }
                            })
                    } else {
                        this.zip_code = false
                    }
                }, 200)
            },
            onSubmit () {
                this.company.type = this.company.user_document.length === 11 ? 'CPF' : 'CNPJ'
                axios.post('/company-data', this.company)
                    .then(
                        this.$emit('recharge')
                    )
            }
        }
    }
</script>

<style scoped>
    .card-body {
        background: white;
    }
    .balance-col {
        text-align: center;
        font-size: 30px;
    }

    .row {
        margin: 5px;
    }

    .align-items-right {
        text-align: right;
    }
</style>
