import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters"

const state = {
    qualificationsList: null,
    qualifications: {},
    qualificationToUpdate: {},
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}