export default {
    setLastCalls(state, payload) {
        state.lastCalls = payload;
    },

    setQualifications(state, payload) {
        state.qualifications = payload;
    },

    setCallsTotalAnswered(state, payload) {
        state.callsTotalAnswered = payload;
    },

    setCallsTotal(state, payload) {
        state.callsTotal = payload;
    },

    setAgentsMetrics(state, payload) {
        state.agentsMetrics = payload;
    },

    setTmaAverage(state, payload) {
        if (payload.length === 0) {
            state.metrics.tma = "0 min";
            return;
        }

        let speakingAverage = payload.reduce((speakingAverage, item) =>
            speakingAverage + (item.speaking_average / item.calls), 0) / payload.length;

        let acwAverage = payload.reduce((speakingAverage, item) =>
            speakingAverage + (item.acw_average / item.calls), 0) / payload.length;

        state.metrics.tma = Math.round(speakingAverage + acwAverage) + " min";
    },


    resetState(state) {
        state.lastCalls = {};
        state.qualifications = {};
        state.callsTotal = {};
        state.callsTotalAnswered = {};
    }
}