import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters"

const state = {
    users: {},
    userToEdit: {},
    updateUserErrors: {},
    userUpdated: false,
    filter: {
        query: '',
        value: ''
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}