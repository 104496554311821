import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters"

const state = {
    user: {},
    campaign: {},
    signupErrors: {},
    loginErrors: {},
    invitedUsersErrors: {},
    updateNameAndPasswordErrors: {},
    updatedStatus: false,
    invitedUsersStatus: false,
    auth: false,
    signupingEmail: '',
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}