export default {
    getQualificationsList(state) {
        return state.qualificationsList;
    },

    getQualifications(state) {
        return state.qualifications;
    },

    getQualificationToUpdate(state) {
        return state.qualificationToUpdate;
    }
}