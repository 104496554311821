export default {
    getCalls(state) {
        return state.calls
    },

    getMetrics(state) {
        return {
            all: state.callsTotal,
            answered: state.callsTotalAnswered
        };
    },

    getIsFiltered(state) {
        return state.activeFilters;
    },

    getFilter(state) {
        return state.filter;
    },

    getQualifications(state) {
        return Object.values(JSON.parse(JSON.stringify(state.qualifications ?? {})));
    }
}