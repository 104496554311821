export default {
    setStep(state, step) {
        state.step = step;
    },

    setStartModal(state, payload) {
        state.startModal = payload;
    },

    resetState(state) {
        state.step = 0;
        state.startModal = false
    },

    setResetPasswordModal(state, payload) {
        state.resetPasswordModal = payload;
    },

    closeResetPasswordModal(state) {
        state.resetPasswordModal = false;
    }
}
