export default {
    user(state) {
        return state.user;
    },

    getToken(state) {
        return state.user.api_token;
    },

    getSignupErrors(state) {
        return state.signupErrors;
    },

    getLoginErrors(state) {
        return state.loginErrors;
    },

    getIsAuthenticated(state) {
        return state.auth;
    },

    getUpdateNameAndPasswordErrors(state) {
        return state.updateNameAndPasswordErrors;
    },

    getInviteUsersErrors(state) {
        return state.invitedUsersErrors;
    },

    getUpdatedStatus(state) {
        return state.updatedStatus;
    },

    getInvitedUsersStatus(state) {
        return state.invitedUsersStatus;
    },

    getCampaign(state) {
        return state.campaign;
    },

    getSignupingEmail(state) {
        return state.signupingEmail;
    }
}