export default {
    getStatus(state) {
        return state.status;
    },
    getCall(state) {
        return state.call;
    },
    getEvent(state) {
        return state.event;
    },
    getEnabled(state) {
        return state.enabled;
    }
}
