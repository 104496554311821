export default {
    dialing(state, event) {
        state.status = 'dialing'
        state.event = event
    },
    connected(state, event) {
        state.status = 'connected'
        state.event = event
        state.call = event.event.call
    },
    trying(state, event) {
        state.status = 'trying'
        state.event = event
    },
    logout(state, event) {
        state.status = false
        state.event = event
    },
    setEnabled(state, payload) {
        state.enabled = payload;
    },
    resetState(state) {
        state.status = false;
        state.call = {};
        state.event = {};
        state.enabled = true;
    },
    failed(state) {
        state.status = 'failed'
    },
    idle(state) {
        state.status = false
    },
    acw(state) {
        state.status = 'acw'
    }
}
