export default {
    getUsers(state) {
        return state.users;
    },

    getUserToEdit(state) {
        return state.userToEdit;
    },

    getUpdateUserErrors(state) {
        return state.updateUserErrors;
    },

    getUpdatedUserStatus(state) {
        return state.userUpdated;
    },

    getFilter(state) {
        return state.filter;
    }
}