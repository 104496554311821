<template>
    <div class="text-center wait-payment">
        <h1>Pix QEVO</h1>

        <p>Escaneie e pague via pix</p>

        <p>
            <img v-bind:src="'data:image/jpeg;base64,'+qrcodebase64" />
        </p>
        <button class="btn btn-primary" v-on:click="copyQrCode">Copiar chave pix</button>
        <p v-if="copy"><i class="fas fa-check-circle" style="color: #008000"></i>copiado</p>

        <p><input type="text" id="qrcode" :value="qrcode" hidden/></p>
        <div>
            <p>Assim que o pagamento for identificado, seus créditos serão inseridos instantaneamente.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ValuesModal",
        props: ['qrcode', 'qrcodebase64'],
        data() {
            return {
                copy: false,
            }
        },
        methods: {
            copyQrCode () {
                let copyQrCode = document.getElementById('qrcode')
                copyQrCode.select()

                navigator.clipboard
                    .writeText(copyQrCode.value)
                    .then(() => {
                        this.copy = true
                    })
                    .catch(() => {
                        this.copy = false
                    })
            }
        }
    }
</script>

<style scoped>
   img {
       width: 200px;
   }
</style>
