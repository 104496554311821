export default {
    setUsers(state, payload) {
        state.users = payload;
    },

    setUserToEdit(state, payload) {
        state.userToEdit = payload;
    },

    setUpdateUserErrors(state, payload) {
        state.updateUserErrors = payload;
    },

    setUserUpdated(state, payload) {
        state.userUpdated = payload;
    },

    setFilter(state, payload) {
        state.filter = payload;
    },

    resetState(state) {
        state.users = {};
    }
}