import {createRouter, createWebHistory} from 'vue-router'
import store from './store/index'

const authenticatedRoutes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./pages/Dashboard'),
        meta: {auth: true}
    }, {
        path: '/reports/calls',
        name: 'calls_report',
        component: () => import('./pages/reports/Calls'),
        meta: {auth: true}
    }, {
        path: '/config/users',
        name: 'config_users',
        component: () => import('./pages/configs/users/Users'),
        meta: {auth: true}
    }, {
        path: '/config/user/:userId/edit',
        name: 'config_user_edit',
        component: () => import('./pages/configs/users/EditUser'),
        meta: {auth: true}
    }, {
        path: '/config/qualifications',
        name: 'config_qualifications',
        component: () => import('./pages/configs/qualifications/Qualifications'),
        meta: {auth: true}
    }, {
        path: '/config/qualifications/edit',
        name: 'config_qualifications_edit',
        component: () => import('./pages/configs/qualifications/CreateOrUpdateQualifications'),
        meta: {auth: true}
    }, {
        path: '/config/qualifications/create',
        name: 'config_qualifications_create',
        component: () => import('./pages/configs/qualifications/CreateOrUpdateQualifications'),
        meta: {auth: true}
    },
];

const unauthenticatedRoutes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/auth/Login'),
        meta: {auth: false}
    }, {
        path: '/signup',
        name: 'sign_up',
        component: () => import('./pages/auth/Signup'),
        meta: {auth: false}
    }, {
        path: '/sent-confirmation-email',
        name: 'sent-confirmation-email',
        component: () => import('./pages/auth/EmailConfirmationFeedback'),
        meta: {auth: false}
    }, {
        path: '/confirm-code/:code',
        name: 'signup-sent-confirmation-email',
        component: () => import('./components/auth/SignupConfirmationCode'),
        meta: {auth: false}
    }, {
        path: '/',
        name: 'origin',
        redirect: {name: 'login'}
    }, {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('./pages/auth/ResetPassword'),
        meta: {auth: false}
    }, {
        path: '/reset-password/:code',
        name: 'reset-password-confirmation-email',
        component: () => import('./components/auth/ResetPasswordConfirmationCode'),
        meta: {auth: false}
    },
];

const routes = authenticatedRoutes.concat(unauthenticatedRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters['auth/getIsAuthenticated']) {
        next('/login')
    } else if (!to.meta.auth && store.getters['auth/getIsAuthenticated']) {
        next('/dashboard')
    } else {
        store.dispatch('router/setRoute', to.name);
        next();
    }
});


export default router
