import ActionCallsService from "../../../services/ActionCallsService";
import moment from "moment";

export default {
    fetchLastCalls({commit}) {
        const callsService = new ActionCallsService();
        callsService.fetchCalls(commit, 'setLastCalls', 1, '', {});
    },

    fetchQualifications({commit}, filter) {
        const callsService = new ActionCallsService();
        callsService.fetchQualifications(commit, 'setQualifications', filter);
    },

    fetchCallsTotals({commit}) {
        const callsService = new ActionCallsService();

        callsService.fetchTotalCallsValue(
            commit,
            'setCallsTotalAnswered',
            {
                dates: {
                    start_date: moment().format('yyyy-MM-DD 00:00:00'),
                    end_date: moment().format('yyyy-MM-DD 23:59:59'),
                }
            },
            7
        )

        callsService.fetchTotalCallsValue(
            commit,
            'setCallsTotal',
            {
                dates: {
                    start_date: moment().format('yyyy-MM-DD 00:00:00'),
                    end_date: moment().format('yyyy-MM-DD 23:59:59'),
                }
            },
            null
        )
    },

    fetchAgentsMetrics({commit}) {
        const callsService = new ActionCallsService();
        callsService.fetchAgentsMetrics(commit, 'setAgentsMetrics')
    },

    resetState({commit}) {
        commit('resetState');
    }
}
