export default {
    dialing({commit}, event) {
        commit('dialing', event);
    },
    connected({commit}, event) {
        commit('connected', event);
    },
    trying({commit}, event) {
        commit('trying', event);
    },
    idle({commit}) {
        commit('idle')
    },
    acw({commit}) {
        commit('acw')
    },
    logout({commit}, event) {
        commit('logout', event);
    },
    failed({commit}) {
        commit('failed');
    },
    setEnabled({commit}, payload) {
        commit('setEnabled', payload);
    },
    resetState({commit}) {
        commit('resetState');
    }
}
