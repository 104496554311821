export default {
    login(state, user) {
        state.auth = true;
        state.user = user;
    },

    setUser(state, user) {
        state.user = user;
    },

    logout(state) {
        state.user = {};
        state.auth = false;
    },

    setSignupErrors(state, payload) {
        state.signupErrors = payload;
    },

    clearSignupErrors(state) {
        state.signupErrors = {};
    },

    setLoginErrors(state, payload) {
        state.loginErrors = payload;
    },

    clearLoginErrors(state) {
        state.loginErrors = {};
    },

    setUpdatedStatus(state, payload) {
        state.updatedStatus = payload;
    },

    setInvitedUsersStatus(state, payload) {
        state.invitedUsersStatus = payload;
    },

    setUpdateNameAndPasswordErrors(state, payload) {
        state.updateNameAndPasswordErrors = payload;
    },

    setInviteUsersErrors(state, payload) {
        state.invitedUsersErrors = payload;
    },

    setUserCampaign(state, payload) {
        state.campaign = payload;
    },

    setSignupingEmail(state, payload) {
        state.signupingEmail = payload;
    },

    updateBalance(state, payload) {
      state.user.company.balance -= payload.billed_value
    },

    resetState(state) {
        state.user = {}
        state.campaign = {}
        state.signupErrors = {}
        state.loginErrors = {}
        state.invitedUsersErrors = {}
        state.updateNameAndPasswordErrors = {}
        state.updatedStatus = false
        state.invitedUsersStatus = false
        state.auth = false
        state.signupingEmail = ''
    }
}
