<template>
  <audio class="extension-audio" id="audio" ref="audio" autoplay></audio>
  <div>
    <button v-if="!registered"
            class="btn btn-primary dtmf-keyboard rounded-circle raise">
      <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
      <span class="visually-hidden">Loading...</span>
    </button>
    <button  v-else v-on:click="showKeypad"
             class="btn btn-primary dtmf-keyboard rounded-circle raise"
             data-bs-toggle="offcanvas"
             href="#sidebarOffcanvasExtension"
             aria-controls="sidebarOffcanvasSearch">
      <span style="font-size: 25px"><i class="fe fe-phone"></i></span>
    </button>
    <span class="hidden" id="upgraded-plan" ref="modal" data-bs-toggle="modal" data-bs-target="#upgradedPlanModal" data-bs-placement="top" title=""></span>
    <span class="hidden" id="modalpix" ref="modal" data-bs-toggle="modal" data-bs-target="#modalpix" data-bs-placement="top" title=""></span>

    <div class="offcanvas offcanvas-end" data-bs-backdrop="true" id="sidebarOffcanvasExtension" tabindex="-1">
      <div class="offcanvas-body shadow-lg">
        <div id="status">
          <div class="row mb-5">
            <div class="col-10">
              <h3>Webphone</h3>
            </div>
            <div class="col">
              <a
                class="btn-close"
                href="#"
                data-bs-dismiss="offcanvas"
                aria-label="Close">
              </a>
            </div>
          </div>
        </div>
        <div class="alert alert-secondary" :class="callStatus.class"  role="alert">
          <div v-if="callStatus.progress !== undefined" :style="{ width: (counter/callStatus.progress) * 100 + '%' }" class="progress-call"></div>
          <div v-if="shouldShowCounter()" class="formatted-counter">({{  formattedCounter(counter) }})</div>
          {{ callStatus.label }}
        </div>
        <div id="number">
          <div v-if="(!status || status === 'failed')" class="input-group mb-3">
            <input type="text"
                   class="form-control form-control-flush form-control-auto phone-number"
                   placeholder="(XX) XXXXX-XXXX"
                   v-maska="['(##) ####-####','(##) #####-####']"
                   v-model="number">
            <span @click="clearNumber()" class="fe fe-delete"></span>
          </div>
          <div v-else class="d-inline-block">
            <input type="text"
                   class="disabled-phone form-control form-control-flush form-control-auto phone-number"
                   placeholder="(XX) XXXXX-XXXX"
                   v-maska="['(##) ####-####','(##) #####-####']"
                   :value="number"
                   disabled>
            <button class="btn hide-keypad" @click="toggleHideKeyboard">
              <img
                v-if="hideKeyboard"
                src="../assets/img/keypad-icon.svg"
                class="keypad-img"
                alt="..."
              />
              <i v-else class="fe fe-chevron-up"></i>
            </button>
          </div>
        </div>
        <hr>
        <div id="keyboard" v-if="!(hideKeyboard && status !== 'failed' && status !== false)">
            <input v-if="status === 'connected'" type="text" class="form-control in-call-input" v-model="inCallInput" disabled>
            <div v-for="line in padDigits" :key="line" class="d-flex justify-content-between">
              <div v-for="digit in line" :key="digit"
                   class="keyboard-number btn btn-lg btn-rounded-circle btn-white"

                   @click="buttonPressed(digit)">
                {{ digit }}
              </div>
            </div>

        </div>
        <div id="qualifications"  v-if="status === 'connected' || status === 'acw'">
          <div class="qualification">
            <span class="qualification-title">Qualificação</span>
            <multiselect
              v-model="call_qualification"
              deselectLabel=''
              selectLabel=''
              selectedLabel=''
              placeholder="Adicionar Qualificação"
              track-by="id"
              :options="qualifications">
              <template v-slot:singleLabel>
                <div class="multiselect-single-label">
                  <span class="option__small" :style="{color: call_qualification.color }">&#x2B24;</span>
                  <span class="option__title">
                  <strong>{{call_qualification.name}}</strong>
                  </span>
                </div>
              </template>

              <template v-slot:option="{ option }">
                <div class="option__desc">
                  <span class="option__small" :style="{color: option.color }">&#x2B24;</span>
                  <span class="option__title">
                  <strong>{{option.name}}</strong>
                  </span>

                </div>
              </template>
            </multiselect>
          </div>
          <button v-if="status === 'acw'" class="btn btn-secondary save-qualification" @click="saveQualification()">Finalizar</button>
        </div>
        <div class="d-flex justify-content-center mt-5 mb-5">
          <button v-if="!status || status === 'failed'"
                  @click="dial()"
                  class="btn btn-primary rounded-circle"
                  style="padding-right: 15px;padding-left: 15px;">
            <span style="font-size: 23px"><i class="fe fe-phone"></i></span>
          </button>
          <button v-if="status === 'connected' || status === 'dialing' || status === 'trying'"
                  @click="hangup()"
                  class="btn btn-danger rounded-circle"
                  style="padding-right: 15px;padding-left: 15px;">
            <span style="font-size: 23px"><i class="fe fe-phone"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {maska} from 'maska'
  import JsSIP from './JsSip'
  import Multiselect from 'vue-multiselect'

  require('md-gum-polyfill')
  import axios from 'axios'
  import clientSdk from './sdk'

  export default {
    directives: {maska},
    components: {
      Multiselect
    },
    name: 'Dialpad',
    props: {
      msg: String
    },

    data() {
      return {
        number: '',
        mask: '(##) #####-####',
        numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '*', '#'],
        padDigits: [
          [1, 2, 3],
          [4, 5, 6],
          [7, 8, 9],
          ['*', 0, '#'],
        ],
        connection: null,
        ua: null,
        registered: false,
        registering: false,
        inCall: false,
        wsAttempts: 0,
        registerAttempts: 0,
        session: null,
        muted: false,
        label: '',
        keypad: false,
        host: '',
        extension: '',
        extensionpwd: '',
        hideKeyboard: true,
        socket3cplus: undefined,
        counter: 0,
        interval: false,
        isQualificationTime: false,
        call_qualification: null,
        inCallInput: '',
        qualifications: [],
        qualification_note: '',
        qualified: false
      }
    },

    mounted() {
      this.socketConnect()
    },

    computed: {
      status() {
        return this.$store.getters['extension/getStatus'];
      },
      isMobile() {
        return window.innerWidth < 600
      },
      extensionEnabled() {
        return this.$store.getters['extension/getEnabled'];
      },
      callStatus() {
        if (this.status === 'trying') {
          return {
            label: 'Conectando...',
            class: 'trying',
          }
        }

        if (this.status === 'dialing') {
          this.$store.dispatch('extension/setEnabled', false);
          return {
            label: 'Chamando',
            class: 'dialing',
          };
        }

        if (this.status === 'calling') {
          this.$store.dispatch('extension/setEnabled', false);
          return {
            label: 'Chamando',
            class: 'calling',
          };
        }

        if (this.status === 'connected') {
          this.$store.dispatch('extension/setEnabled', false);
          return {
            label: 'Ligação conectada',
            class: 'connected',
          };
        }

        if (this.status === 'failed') {
          this.$store.dispatch('extension/setEnabled', false);

          return {
            label: 'Ligação Falhou, tente novamente',
            class: 'failed',
            progress: 10
          };
        }

        if (this.status === 'acw') {
          this.$store.dispatch('extension/setEnabled', false);

          return {
            label: 'Qualifique a chamada!',
            class: 'acw',
            progress: 120
          };
        }

        return {
          label: 'Disponível',
          class: 'idle',
        };
      }
    },

    methods: {
      socketConnect () {
        if (Object.prototype.hasOwnProperty.call(this.$store.getters['auth/user'], 'company') && !this.registered) {
          this.host = this.$store.getters['auth/user'].company.domain + '.' + process.env.VUE_APP_TCPLUS_DOMAIN
          this.extensionpwd = this.$store.getters['auth/user'].extension_password
          this.extension = this.$store.getters['auth/user'].telephony_id
          this.clickRegister()

          this.socket3cplus = clientSdk().realtime().integration()

          if (this.socket3cplus === undefined) {
            document.location.reload()
          }

          this.socket3cplus.on('connect', () => clientSdk().agent().connect().catch(() => console.log('Error connecting the agent')))
          this.socket3cplus.on('agent-is-idle', (event) => {
            if (event.agent.id !== this.$store.getters['auth/user'].id) {
              return true
            }

            if (this.$store.getters['extension/getStatus'] && !this.qualified) {
              this.resetCounter();
              this.$store.dispatch('extension/acw');
              setTimeout(() => {
                if (this.status === 'acw') {
                  axios.post('/agent/logout')
                  this.$store.dispatch('extension/idle')
                }
              }, 120000)
            } else if (this.$store.getters['extension/getStatus']) {
              axios.post('/agent/logout')
              this.$store.dispatch('extension/logout', {
                event
              });
            } else {
              this.call_qualification = null
              this.qualified = false
              this.inCallInput = ''
              axios.post('/click2call',
                {
                  'phone': this.number.replace(/\D/g, ''),
                  'extension': this.$store.getters['auth/user'].extension.extension_number
                }
              ).catch(() => {
                axios.post('/agent/logout')
                this.$store.dispatch('extension/failed')
                this.resetCounter();
                setTimeout(() => {
                  if (this.status === 'failed') {
                    this.$store.dispatch('extension/idle')
                  }
                }, 10000)
              })
              this.resetCounter();
              this.$store.dispatch('extension/trying', {
                event
              });
            }

          })

          this.socket3cplus.on('call-history-was-created', (event) => {
            this.$store.dispatch('auth/updateBalance', {
              event
            });

          })
          this.socket3cplus.on('call-was-connected', (event) => {
            if (event.agent.id !== this.$store.getters['auth/user'].id) {
              return true
            }
            this.resetCounter();
            this.qualifications = event.qualification.qualifications

            this.$store.dispatch('extension/dialing', {
              event
            });
          })
          this.socket3cplus.on('call-was-answered', (event) => {
            if (parseInt(event.call.agent) !== this.$store.getters['auth/user'].id) {
              return true
            }
            this.resetCounter();

            this.$store.dispatch('extension/connected', {
              event
            });
          })
        } else if (!this.registered) {
          setTimeout(() => {
            this.socketConnect()
          }, 1000)
        }

      },
      saveQualification() {
        this.qualified = true
        if (this.call_qualification !== null) {
          axios.post('/agent/manual_call/' + this.$store.getters['extension/getCall'].telephony_id + '/qualify',
            {
              qualification_id: this.call_qualification.id
            })
        }
        axios.post('/agent/logout')
        this.$store.dispatch('extension/logout');
        this.$store.dispatch('extension/setEnabled', true);
      },
      dial() {
        axios.post('/agent/login', {'campaign': this.$store.getters['auth/getCampaign'].id})
          .catch(() => {
            axios.post('/agent/logout')
            this.$store.dispatch('extension/failed')
            this.resetCounter();
            setTimeout(() => {
              if (this.status === 'failed') {
                this.$store.dispatch('extension/idle')
              }
            }, 10000)
          })
      },
      hangup() {
        if (this.status === 'trying' || this.status === 'dialing') {
          axios.post('/agent/logout')
          this.$store.dispatch('extension/idle')
        } else {
          axios.post('/agent/call/' + this.$store.getters['extension/getCall'].telephony_id + '/hangup')
            .then(() => {
              this.clearNumber();
            }).catch(() => {
              axios.post('/agent/logout')
              this.$store.dispatch('extension/acw')
            })
        }
      },
      showKeypad() {
        this.keypad = !this.keypad
      },
      clearNumber() {
        this.number = '';
      },
      formattedCounter(value) {
        let negative = ''
        if (value < 0) {
          negative = '-'
          value = -value
        }
        let hours = Math.floor(value / 3600)
        let minutes = Math.floor((value - (hours * 3600)) / 60)
        let seconds = (value - ((hours * 3600) + (minutes * 60))) % 60

        let dHours = (hours > 9 ? hours : '0' + hours)
        let dMins = (minutes > 9 ? minutes : '0' + minutes)
        let dSecs = (seconds > 9 ? seconds : '0' + seconds)

        return negative + dHours + ':' + dMins + ':' + dSecs
      },
      addDigit(digit) {

        const found = this.numbers.find(element => element == digit);

        if (found || found === 0) {
          this.number += digit;
        }
      },
      addInCallDigit(digit) {
        this.inCallInput += digit;
      },
      buttonPressed(digit) {
        if(this.inCall) {
          this.session.sendDTMF(digit)
          this.addInCallDigit(digit)
        } else {
          this.addDigit(digit)
        }
      },
      clickRegister() {
        this.registerExtension()
      },
      showErrorWebextension() {
        console.log('Ramal web não está habilitado para este agente.')
      },
      showErrorRegister() {
        console.log('Não foi possível conectar. Por favor, tente recarregar a página.')
      },
      showGetUserMediaError() {
        console.log('Verifique se o seu microfone está conectado e permitido no navegador.')
      },
      registerExtension() {
        this.registering = true
        this.ua = JsSIP(this.host, this.extension, this.extensionpwd, '4443')

        this.ua.start()

        navigator.mediaDevices
          .getUserMedia({audio: true})
          .then(() => console.log('Permission is granted!'))
          .catch(() => this.showGetUserMediaError())

        this.ua.on('connected', () => {
          this.label = 'Conectado'
          this.wsAttempts = 0
        })

        this.ua.on('disconnected', (e) => {
          console.log('disconnected, trying reconnect...', e)
          this.label = 'Desconectado, tentando conectar...'
          this.wsAttempts++
          let attempts = 20
          if (this.wsAttempts > attempts) {
            this.registering = false
            this.showErrorRegister()
            this.ua.stop()
            setTimeout(() => {
              this.registerExtension()
            }, 1000)
          }
        })

        this.ua.on('newRTCSession', (data) => {
          let session = data.session
          this.session = session

          session.on('accepted', () => {
            this.inCall = true
            this.session.unmute()
          })

          session.on('peerconnection', (data) => {
            data.peerconnection.addEventListener('addstream', (data) => {
              document.getElementById('audio').srcObject = data.stream
              document.getElementById('audio').play()
            })
          })

          session.on('getusermediafailed', () => {
            this.showGetUserMediaError()
          })

          session.on('ended', () => {
            console.log('ended')
            this.session = null
            this.inCall = false
            this.muted = false
          })

          session.on('muted', () => {
            this.muted = true
          })

          session.on('unmuted', () => {
            this.muted = false
          })

          session.answer({
            mediaConstraints: {
              audio: true,
              video: false
            }
          })
        })

        this.ua.on('registered', () => {
          console.log('registered')
          this.registerAttempts = 0
          this.label = 'Ramal registrado'
          this.registered = true
          if (this.status === 'acw') {
            axios.post('/agent/logout')
            this.$store.dispatch('extension/logout', {
              event
            });
          }
        })

        this.ua.on('unregistered', () => {
          console.log('unregistered')
          this.label = 'Ramal desconectado, tentando conectar...'
          this.registered = false
          setTimeout(() => {
            this.registerExtension()
          }, 1000)
        })

        this.ua.on('registrationFailed', (e) => {
          console.log('registrationFailed', e)
          this.label = 'Tentando registrar...'
          this.registered = false
          setTimeout(() => {
            if (this.ua.isConnected() && !this.ua.isRegistered()) {
              if (this.registerAttempts <= 10) {
                console.log('trying register...')
                this.label = 'Tentando registrar...'
                this.registerExtension()
                this.registerAttempts++
              } else {
                console.log('Register 10 attempts')
                this.registering = false
                this.showErrorRegister()
              }
            }
          }, 5000)
        })
      },
      unregister() {
        this.registered = false
        this.webextension = false
        this.ua.stop()
      },
      showProtocolError() {
        console.log('Não foi possível ativar. Por favor, tente recarregar a página.')
      },
      toggleHideKeyboard () {
        this.hideKeyboard = !this.hideKeyboard
      },
      shouldShowCounter () {
        switch (this.status) {
          case 'connected':
          case 'trying':
          case 'dialing':
          case 'acw':
            return true;
          default:
            return false
        }
      },
      resetCounter() {
        this.counter = 0
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.counter++
        }, 1000)
      }
    }
  }
</script>

<style scoped>
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="offcanvas"] {
      width: 100%;
    }
  }

  .phone-icon {
    width: 55px;
    height: 55px;
    margin-bottom: 65px;
    cursor: pointer;
  }

  .dtmf-keyboard {
    width: 68px;
    height: 68px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .keypad .dtmf-button button {
    height: 60px;
    border-radius: 50%;
    border: none;
    min-width: 60px;
    box-shadow: none;
    background-color: transparent !important;
    font-weight: 300;
  }

  .keypad .dtmf-button button .number {
    display: block;
    font-size: 25px;
    line-height: 25px;
  }

  .keypad .dtmf-button button .letter {
    font-size: 12px;
    color: #8f8f8f;
  }

  .keypad .dtmf-button button span {
    display: block;
  }

  .keyboard-number {
    margin: 8px 8px 8px 8px;
    font-weight: bold;
    font-size: 16px;
  }

  .keyboard-number:active {
    background-color: rgba(211, 211, 211, 0.82);
  }

  .phone-number {
    font-size: 22px !important;
  }

  .fe-delete {
    font-size: 20px !important;
    cursor: pointer;
  }

  #number {
    margin-top: 35px;
  }

  .badge {
    width: 100% !important;
    padding: 10px;
    border-radius: 0;
  }

  .badge {
    text-align: left !important;
    font-weight: bold !important;
  }

  .save-qualification {
    width: 100%;
  }

  .extension-audio {
    display: none;
  }

  textarea {
    resize: none;
  }

  .number-disabled {
    background-color: #dfdfdf
  }

  .idle {
    text-align: center;
    font-size: 22px;
    background-color: #edf2f9;
    color: #2c7be5;
    border: none;
    padding: 7px;
  }

  .trying {
    text-align: center;
    font-size: 22px;
    background-color: #edf2f9;
    color: #2c7be5;
    border: none;
    padding: 7px;
  }

  .dialing {
    text-align: center;
    font-size: 22px;
    background-color: #edf2f9;
    color: #2c7be5;
    border: none;
    padding: 7px;
  }

  .connected {
    text-align: center;
    font-size: 22px;
    background-color: #e7f6e8;
    color: #38bb3f;
    border: none;
    padding: 7px;
  }

  .acw {
    text-align: center;
    font-size: 22px;
    background-color: #e7d9f0;
    color: #863fb3;
    border: none;
    padding: 17px 7px 7px;
  }

  .failed {
    text-align: center;
    font-size: 14px;
    background-color: #f5f5f5;
    color: #8492a6;
    border: none;
    padding: 17px 7px 7px;
  }

  .progress-call {
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 10px 10px 0;
  }

  .failed .progress-call {
    background-color: #8492a6;
  }

  .acw .progress-call {
    background-color: #863fb3;
  }

  .disabled-phone {
    background-color: #f5f5f5 !important;
    padding: 10px 15px;
    width: 230px;
    font-size: 21px !important;
    display: inline-block;
  }

  .hide-keypad {
    background-color: #f5f5f5;
    margin-top: -5px;
    padding: 7px 10px;
    margin-left: 4px;
  }

  .hide-keypad img{
    width: 30px;
    height: 37px;
  }

  .formatted-counter {
    font-size: 12px;
  }

  .fe-chevron-up {
    font-size: 25px;
  }
  .qualification-title {
    font-size: 20px;
  }

  .qualification {
    background-color: #f5f5f5;
  }

  .in-call-input {
    width: 80%;
    margin-left: 10%;
  }
</style>
