import ActionCallsService from "../../../../services/ActionCallsService";
import store from '../../../index';

export default {
    fetchCalls({commit}, {page, search}) {
        const callsService = new ActionCallsService();
        callsService.fetchCalls(commit, 'setCallsInformation', page, search, store.getters['reportCalls/getFilter'] || {});
        store.dispatch('reportCalls/fetchCallsTotal');
    },

    fetchCallsTotal({commit}) {
        const callsService = new ActionCallsService();

        callsService.fetchTotalCallsValue(
            commit,
            'setCallsTotalAnswered',
            store.getters['reportCalls/getFilter'] || {},
            7
        )

        callsService.fetchTotalCallsValue(
            commit,
            'setCallsTotal',
            store.getters['reportCalls/getFilter'] || {},
            null
        )
    },

    fetchQualifications({commit}) {
        const callsService = new ActionCallsService();
        callsService.fetchQualifications(commit, 'setQualifications', store.getters['reportCalls/getFilter'] || {});
    },

    setFilter({commit}, payload, search) {
        let quantityActiveFilters = 0;

        if (payload != null) {
            // eslint-disable-next-line no-prototype-builtins
            if (payload.hasOwnProperty('dates')) {
                quantityActiveFilters++;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (payload.hasOwnProperty('users')) {
                quantityActiveFilters++;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (payload.hasOwnProperty('status')) {
                quantityActiveFilters++;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (payload.hasOwnProperty('qualifications')) {
                quantityActiveFilters++;
            }
        }

        commit('setActiveFilters', quantityActiveFilters);
        commit('setFilter', payload);
        store.dispatch('reportCalls/fetchCalls', {page: 1, search: search});
        store.dispatch('reportCalls/fetchQualifications');
        store.dispatch('reportCalls/fetchCallsTotal');
    },

    resetState({commit}) {
        commit('resetState')
    }
}