import axios from "axios";
import store from "../../index";

export default {
    fetchUsers({commit}, {page, search}) {
        const filterValue = store.getters['users/getFilter'].value;

        axios.get(`/users`, {
            params: {
                'page': page,
                'search': search,
                'active': filterValue
            },
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(response => {
            commit('setUsers', response.data);
        });
    },

    setFilters({commit}, payload) {
        commit('setFilter', payload);
        store.dispatch('users/fetchUsers', {page: 1, search: ''});
    },

    // eslint-disable-next-line no-unused-vars
    fetchDeleteUser({commit}, userId) {
        axios.delete(`/users/${userId}`, {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(() => {
            store.dispatch('users/fetchUsers', {page: 1, search: ''});
        });
    },

    fetchUser({commit}, userId) {
        commit('setUpdateUserErrors', {});
        commit('setUserUpdated', false);
        axios.get(`/users/${userId}`, {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(response => {
            commit('setUserToEdit', response.data.data);
        });
    },

    fetchUpdateUser({commit}, {userId, payload}) {
        commit('setUpdateUserErrors', {});

        axios.put(`/users/${userId}`, payload, {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(() => {
            commit('setUserUpdated', true);
            store.dispatch('users/fetchUsers', {page: 1, search: ''});
        }).catch(errors => {
            commit('setUserUpdated', false);
            commit('setUpdateUserErrors', errors.response.data.errors);
        });
    },

    // eslint-disable-next-line no-unused-vars
    fetchResendConfirmationEmail({commit}, payload) {
        axios.post(`/resend-invite-confirmation`, payload);
    },

    resetState({commit}) {
        commit('resetState');
    }
}