export default {
    getLastCalls(state) {
        return state.lastCalls?.data?.data;
    },

    getQualifications(state) {
        return Object.values(JSON.parse(JSON.stringify(state.qualifications ?? {})));
    },

    getAgentsMetrics(state) {
        return state.agentsMetrics
    },

    getMetrics(state) {
        return {
            all: state.callsTotal,
            answered: state.callsTotalAnswered
        };
    },
}