import axios from "axios";
import store from "../../index";

export default {
    fetchQualificationsList({commit}) {
        axios.get('/qualification_lists').then(response => {
            commit('setQualificationList', response.data.data[0]);
        });
    },

    fetchQualifications({commit}) {
        const uri = `/qualification_lists/${store.getters['qualifications/getQualificationsList'].id}/qualifications`;

        axios.get(uri).then(response => {
            commit('setQualification', response.data);
        });
    },

    setQualificationToUpdate({commit}, qualification) {
        commit('setQualificationToUpdate', qualification);
    },

    setQualificationsSortable({commit}, {payload, ids}) {
        let uri = `/qualification_lists/${store.getters['qualifications/getQualificationsList'].id}/order`;

        axios.post(uri, {}, {
            params: {
                list: ids
            }
        })

        commit('setQualificationData', payload);
    },

    // eslint-disable-next-line no-unused-vars
    fetchUpdateQualification({commit}, {id, payload}) {
        const uri = `/qualification_lists/${store.getters['qualifications/getQualificationsList'].id}/qualifications/${id}`;

        axios.put(uri, payload).then(() => {
            store.dispatch('qualifications/fetchQualifications');
        });
    },

    // eslint-disable-next-line no-unused-vars
    fetchStoreQualification({commit}, payload) {
        const uri = `/qualification_lists/${store.getters['qualifications/getQualificationsList'].id}/qualifications`;

        axios.post(uri, payload).then(() => {
            store.dispatch('qualifications/fetchQualifications');
        });
    },

    // eslint-disable-next-line no-unused-vars
    fetchDeleteQualification({commit}, {id}) {
        const uri = `/qualification_lists/${store.getters['qualifications/getQualificationsList'].id}/qualifications/${id}`;

        axios.delete(uri).then(() => {
            store.dispatch('qualifications/fetchQualifications');
        });
    }
}