import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
//Modules import
import auth from './modules/auth'
import reportCalls from './modules/reports/calls';
import dashboard from './modules/dashboard';
import welcomeModal from './modules/welcomeModal'
import users from './modules/users'
import qualifications from './modules/qualifications'
import router from './modules/routerToSidebar'
import extension from './modules/extension'

const plugins = [createPersistedState()];

const store = createStore({
    modules: {
        auth,
        reportCalls,
        dashboard,
        welcomeModal,
        users,
        router,
        extension,
        qualifications
    },

    plugins
})

export default store;
