import axios from "axios";
import router from "../../../router";
import store from '../../index'

export default {
    login({commit}, credentials) {
        commit('clearLoginErrors');

        axios.post('/webphone-only/authenticate', credentials)
            .then((response) => {
                store.dispatch('extension/setEnabled', false);
                this.dispatch('auth/fetchUserCampaign');
                commit('login', response.data.data);
            })
            .catch((error) => {
                commit('setLoginErrors', error.response);
            })
    },

    signup({commit}, payload) {
        commit('clearSignupErrors');
        axios.post('/register-webphone', payload)
            .then(() => {
                commit('setSignupingEmail', payload.email);
                router.push('/sent-confirmation-email');
            })
            .catch((error) => {
                commit('setSignupErrors', error.response.data.errors);
            })

    },

    confirmSignupCode({commit}, payload) {
        axios.post('/confirm', payload)
            .then((response) => {
                commit('login', response.data.data);
                this.dispatch('auth/fetchUserCampaign')
            })
    },

    updateUser({commit}, payload) {
        commit('setUpdatedStatus', false);

        axios.put(`/users/${payload.user_id}/basic-data`, payload, {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        })
            .then((response) => {
                commit('setUser', response.data.data);
                commit('setUpdatedStatus', true);
            })
            .catch(error => {
                commit('setUpdateNameAndPasswordErrors', error.response.data.errors);
            })
    },

    inviteUsers({commit}, payload) {
        commit('setInvitedUsersStatus', false);

        axios.post(`/webphone/users`, payload, {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        })
            .then(() => {
                commit('setInviteUsersErrors', {});
                commit('setInvitedUsersStatus', true);
                store.dispatch('users/fetchUsers', {page: 1, search: ''});
            })
            .catch((error) => {
                commit('setInviteUsersErrors', error.response.data.errors)
            })
    },

    setInviteUserStatus({commit}, payload) {
        commit('setInvitedUsersStatus', payload);
    },

    logout({commit}) {
        commit('resetState');
        router.push('/login');
        store.dispatch('dashboard/resetState');
        store.dispatch('reportCalls/resetState');
        store.dispatch('users/resetState');
        store.dispatch('welcomeModal/resetState');
        store.dispatch('extension/resetState');
        commit('logout');
    },

    resetSignupErrors({commit}) {
        commit('clearSignupErrors');
    },

    resetInviteUsersErrors({commit}) {
        commit('setInviteUsersErrors', {});
    },

    fetchUserCampaign({commit}) {
        axios.get('/agent/campaigns', {
            headers: {
                'Authorization': `Bearer ${store.getters['auth/getToken']}`
            }
        }).then(response => {
            commit('setUserCampaign', response.data.data[0])
            router.push('/dashboard');
        });
    },

    resetPassword({commit}, payload) {
        axios.post('/forget-password', {
            email: payload.email
        })
        commit('clearSignupErrors')
    },

    resendConfirmationEmail({commit}, payload) {
        axios.post('/resend-confirmation', {
            email: payload.email
        })
        commit('clearSignupErrors')
    },
    updateBalance({commit}, payload) {
        console.log(payload)
        console.log(payload.event.callHistory)
        commit('updateBalance', payload.event.callHistory)
    }
}
