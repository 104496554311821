import jsSip from 'jssip'

export default function JsSip (host, extension, extensionpwd, port) {
  let socket = new jsSip.WebSocketInterface('wss://' + host + ':' + port)
  let configuration = {
    sockets: [ socket ],
    uri: 'sip:' + extension + '@' + host,
    password: extensionpwd,
    register: true,
    register_expires: 30,
    session_timers: false
  }

  return new jsSip.UA(configuration)
}

