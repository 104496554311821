export default {
    setCallsInformation(state, payload) {
        state.calls = payload.data;
    },

    setTmaAverage(state, payload) {
        const speakingAverage = payload.reduce((speakingAverage, item) =>
            speakingAverage + (item.speaking_average / item.calls), 0) / payload.length ?? 1;

        const acwAverage = payload.reduce((speakingAverage, item) =>
            speakingAverage + (item.acw_average / item.calls), 0) / payload.length ?? 1;

        let result = Math.round(speakingAverage / acwAverage);

        state.metrics.tma = (Number.isNaN(result) ? 0 : result) + " min";
    },

    setCallsTotal(state, payload) {
        state.callsTotal = payload
    },

    setCallsTotalAnswered(state, payload) {
        state.callsTotalAnswered = payload;
    },

    setQualifications(state, payload) {
        state.qualifications = payload;
    },

    setFilter(state, payload) {
        state.filter = payload;
    },

    setActiveFilters(state, payload) {
        state.activeFilters = payload;
    },

    resetState(state) {
        state.calls = {};
        state.nextPage = null;
        state.previousPage = null;
        state.totalPages = 0;
        state.currentPage = 0;

        state.metrics = {
            billedValue: 0,
            totalCalls: 0,
            answeredCalls: 0,
            completion: 0,
            tma: 0,
        };

        state.qualifications = {};
    }
}