import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters"

const state = {
    status: false,
    call: {},
    event: {},
    enabled: true,
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
